import React, { useContext, useState, useEffect } from "react";
import { auth, firestore, FIREBASE } from "../../firebase";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  async function signup(email, password,username) {
    return auth.createUserWithEmailAndPassword(email, password).then(cred=> {
        return firestore.collection('users').doc(cred.user.uid).set({
          username : username,
          email : email,
          credit : 10,
        });
    })
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password).then(cred => {
      return createUserDocument(cred.user, 'user');
    })

  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  async function getDocNames(collection) {
    const snapshot = await firestore.collection(collection).get();
    return snapshot.docs.map(doc => doc.id);
  }

  async function getDataFromCollectionAndDoc(collection, document) {
    const snapshot = await firestore.collection(collection).get(document);
    for (let idx in snapshot.docs) {
      let doc = snapshot.docs[idx]
      if (doc.id == document) {
        return doc.data();
      }
    }
    return null
  }

  async function getData(collection) {
    const snapshot = await firestore.collection(collection).get();
    let data = {};
    snapshot.docs.map(doc => {data[doc.id] = doc.data()});
    return data;
  }

  async function createUserDocument(user, additionalData) {
    const userRef = firestore.doc(`users/${user.uid}`);

    const snapshot = await userRef.get();

    if (!snapshot.exists) {
      const email = user.email;
      const role = additionalData;
      try {
        await userRef.update({
          email,
          role,
        });
      } catch (error) {
        try {
          await userRef.set({
            email: email,
            role: role,
          });
        } catch (err) {
          console.log(error)
          alert(err);
        }
      }
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {

      if (user == undefined) {
        setCurrentUser(user);
        setLoading(false);
        return;
      }
      const userRef = firestore.doc(`users/${user.uid}`);

      const snapshot = await userRef.get();
      if (snapshot.exists) {
        user.role = snapshot.data().role;
      }
      setCurrentUser(user);
      setLoading(false);
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    createUserDocument,
    getDocNames,
    getDataFromCollectionAndDoc,
    getData
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}