import React from "react"
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "../Contexts/AuthContexts";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  function isAuthenticated() {
    if (currentUser && currentUser.role == "admin") {
      return true;
    }
    return false;
  }

  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;

}